import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` recently surpassed 140 million messages processed.`}</p>
    <p>{`We took a look back at a previous report on common messages sent in to bots to see how messaging has changed over the past five months.`}</p>
    <p>{`The following metrics are based on Facebook bots on our platform.`}</p>
    <h2>{`Hi again, it’s me…`}</h2>
    <p>{`Looking at the most common messages sent in to bots, we can see “hi” and “hello” are still at the top. It makes sense — bots are conversational interfaces and hi and hello are natural ways to start a conversation.`}</p>
    <p>{`In October alone, 73% of bots received a “hi” and 56% received a “hello.” Adding support for these messages can help increase engagement.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152923/0_boY-nwz7h8d2YW1W.png)
    </figure>
### Help me bot, help, help me bot…
    <p>{`Help is also a fairly common message sent in to bots. In October, nearly 40% of bots received a “help” message.`}</p>
    <p>{`Users want to know how to interact with your bot and what it can do. Does your bot have a help response? This is a great opportunity to provide info on what your bot does, and how users can interact with it.`}</p>
    <h2>{`Getting to know you…`}</h2>
    <p>{`We’ve mentioned this before — providing a personality for your bot can increase engagement.`}</p>
    <p>{`We took a look at how often users try to get to know the bots they’re interacting with — as if they are a person.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152922/0_EByuZON-PkVLSdy_.png)
    </figure>
### Say my name, Say my name…
    <p>{`Nearly 19% of Facebook bots have had users ask the bot its name. At the same time, about 5.6% of bots were asked whether they were male or female!`}</p>
    <p>{`Is this real? Interestingly enough, about 11% of bots have received a message asking whether the bot was a real person or not.`}</p>
    <h2>{`Stickers`}</h2>
    <p>{`One of the beauties of conversational interfaces on messaging platforms is the ability to send in unstructured data. Users are sending in more than text — they’re sending images, video, audio and more.`}</p>
    <p>{`Stickers are are one of the ways users express themselves and communicate with bots. Below is an updated version of the most popular stickers users send.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/12/07152922/0_Y_AciDQlHdwFh_9-.png",
          "alt": null
        }}></img></figure>{`Analyzing Conversational Flows`}</h2>
    <p>{`A great way to learn more about how your users interact with your bot, as well improve your bot response effectiveness, is to look at the conversational flows.`}</p>
    <p>{`Our Message Funnel report, recently described by Amir Shevat of Slack as the “holy grail” of analytics at O’Reilly’s BotDay conference, can help identify messages users are sending in that your bot isn’t handling properly, and thus provide an opportunity to improve the bot response effectiveness.`}</p>
    <p>{`With the funnel report, you can navigate the conversational flow through the bot. For example in the image below, you can see the different messages users sent in to the bot that resulted in the current bot response message, as well as what users did afterward. You can navigate in both directions.`}</p>
    <p>{`This is quite helpful when looking at your error or “I don’t know” responses to see what users entered that led to the response, as well as what they did after. Using that information, you can improve your bot’s responses and AI processing.`}</p>
    <p>{`There are multiple ways into the funnel on Dashbot — including from the Top Messages In and Out reports as well as from the Transcripts themselves.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/12/07152921/0_dnetC2WaLm2Jy6lx.png",
          "alt": null
        }}></img></figure>{`Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables bot developers and enterprises to increase user acquisition, engagement, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional metrics like engagement and retention, we have bot specific processing and metrics like conversational analytics, sentiment analysis, and the full chat session transcripts.`}</p>
    <p>{`More importantly, we provide tools that make use of the data to improve acquisition and engagement, like live person take over of chat sessions and push notifications for re-engagement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      